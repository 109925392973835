import React from 'react';

//importing the css
import '../../css/navigation/SectionNav.css';

function SectionNav({currentSection, setSection, ...props}) {
    return ( 
        <>
        <div className="sectionnavwrapper">
            <div className="navsectioncontentwrapper">
                <ul>
                    <li onClick={() => setSection('Kopfdaten')} className={currentSection === "Kopfdaten" ? "activesection" : ""}><span>Kopfdaten</span></li>
                    <li onClick={() => setSection('Bewilligung')} className={currentSection === "Bewilligung" ? "activesection" : ""}><span>Bewilligung</span></li>
                    <li onClick={() => setSection('Lieferketten-Beteiligter')} className={currentSection === "Lieferketten-Beteiligter" ? "activesection" : ""}><span>Lieferketten-Beteiligter</span></li>
                    <li onClick={() => setSection('Wiedereinfuhr')} className={currentSection === "Wiedereinfuhr" ? "activesection" : ""}><span>Wiedereinfuhr</span></li>
                    <li onClick={() => setSection('Nämlichkeitsmittel')} className={currentSection === "Nämlichkeitsmittel" ? "activesection" : ""}><span>Nämlichkeitsmittel</span></li>
                    <li onClick={() => setSection('Erzeugnis')} className={currentSection === "Erzeugnis" ? "activesection" : ""}><span>Erzeugnis</span></li>
                    <li onClick={() => setSection('Vorpapier')} className={currentSection === "Vorpapier" ? "activesection" : ""}><span>Vorpapier</span></li>
                    <li onClick={() => setSection('Unterlage')} className={currentSection === "Unterlage" ? "activesection" : ""}><span>Unterlage</span></li>
                    <li onClick={() => setSection('Sonstiger Verweis')} className={currentSection === "Sonstiger Verweis" ? "activesection" : ""}><span>Sonstiger Verweis</span></li>
                    <li onClick={() => setSection('Zusätzliche Informationen')} className={currentSection === "Zusätzliche Informationen" ? "activesection" : ""}><span>Zusätzliche Informationen</span></li>
                    <li onClick={() => setSection('Transportausrüstung')} className={currentSection === "Transportausrüstung" ? "activesection" : ""}><span>Transportausrüstung</span></li>
                    <li onClick={() => setSection('Beförderungsmittel am Abgang')} className={currentSection === "Beförderungsmittel am Abgang" ? "activesection" : ""}><span>Beförderungsmittel am Abgang</span></li>
                    <li onClick={() => setSection('Beförderungsroute der Sendung')} className={currentSection === "Beförderungsroute der Sendung" ? "activesection" : ""}><span>Beförderungsroute der Sendung</span></li>
                    <li onClick={() => setSection('Transportdokument')} className={currentSection === "Transportdokument" ? "activesection" : ""}><span>Transportdokument</span></li>
                </ul>
            </div>
        </div>
        </>
     );
}

export default SectionNav;