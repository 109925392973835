import { reybexurl } from "../urltemplate";
import { toast } from "react-toastify";

export const ReyBexAuth = async (username, password, goTo) => {

    const sendData = {
        username,
        password
    }

    console.log(sendData);

    try {
        const data = await fetch(`${reybexurl}login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(sendData)
        })

        if (data.status === 500) {
            toast.error("Internal Server Error!", {
                position: "bottom-right"
            })
            throw new Error('Internal Server Error');
        }

        if (!data.ok) {
            throw new Error(`HTTP error! Status: ${data.status}`);
        }

        const response = await data.json();
        console.log(response);

        if (response){
            localStorage.setItem("Rtoken", response.token);
            localStorage.setItem("Ruser", response.userId);
            goTo("/beoatlassetup");
        }
    
    } catch (error) {
        console.error(error)
    }
};