import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

//importing the css
import '../../css/section/createatlasmessage/header/HeaderInformation.css';

//importing the components
import Inputfield from '../inputfields/InputField';
import DropDown from '../inputfields/DropDown';
import AccordionForm from '../forms/AccordionForm';
import TextDropDownButton from '../inputfields/TextDropDownButton';

//Loading Animation. 
import LoadingPage from '../../pages/Userpages/addons/LoadingPage';

import { reybexurl } from '../../apis/urltemplate';

function FillHeaderInformation({ onChange }) {

    const { LsNumber } = useParams();
    const [DeliveryNoteData, setDeliveryNoteData] = useState(null);

    React.useEffect(() => {
        const GetDeliveryNoteData = async () => {
            try {
                // Token aus dem Local Storage abrufen
                const token = localStorage.getItem("Rtoken");
    
                // Anfrage an die API senden
                const response = await fetch(`${reybexurl}domains/salesHead?docType.name=Lieferschein&docNumber=${LsNumber}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Basic ${token}`
                    }
                });
    
                // Antwort überprüfen und Daten verarbeiten
                if (response.ok) {
                    const data = await response.json();
                    setDeliveryNoteData(data);
                    console.log("These are the pending deliveries:", data);
                    const lrnValue = data[0].docNumber;
                    const UcrValue = data[0].docNumber;
                    const GrossWeigt = data[0].grossWeight;

                    // Daten setzen
                    setMainHeaderData(prevData => ({
                        ...prevData,
                        lrn: lrnValue,
                        referenznummerUCR: UcrValue,
                        gesamtRohmasse: GrossWeigt
                    }));

                } else {
                    throw new Error('Fehler beim Abrufen der Daten');
                }
            } catch (error) {
                console.error(error);
            }
        };
    
        GetDeliveryNoteData();
    
    }, [LsNumber]);
    

    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

    const toggleDropdown = (index) => {
        setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    };


    //This is for sending the MainHeader information:
    const [mainHeaderData, setMainHeaderData] = useState({
        messageNumber: "01001359240417083600000000000004019",
        dienststellennummer: "",
        lrn: "",
        artderAnmeldung: "",
        artderAusfuhranmeldung: "",
        beteiligtenKonstellation: "",
        zeitpunktderAnmeldung: "",
        massgeblichesDatum: "",
        kopfDatumdesAusgangs: "",
        zeitpunktDerGestellung: "",
        zeitpunktdesEndesderLadetatigkeit: "",
        sicherheit: "",
        besondereUmstande: "",
        inRechnunggestellterGesamtbetrag: "",
        rechnungswahrung: "",
        containerIndikator: "0",
        gesamtRohmasse: "",
        referenznummerUCR: "",
        registriernummerextern: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e; // Verwenden Sie das Ereignisobjekt direkt
        setMainHeaderData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            onChange(mainHeaderData);
        }, 30000);
    
        return () => clearInterval(interval);
      }, [mainHeaderData, onChange]); 


    return ( 
        <>
        {DeliveryNoteData && DeliveryNoteData.length > 0 ? (<>
            <div className="beo_mainpage_grid">
            <div className="header_left_column_wrapper">
                <h2>Allgemeine Informationen für die Ausfuhranmeldung</h2>
                <div className="form_wrapper">
                    <Inputfield
                    fieldname="Nachrichtennummer"
                    placeholder="Hallo Welt!"
                    value={mainHeaderData.messageNumber}
                    type="text"
                    styling="disabled"
                    readOnly={true}
                    mandatory="none"
                    />
                    <DropDown
                    fieldname="EORI- u. Niederlassungsnummer"
                    zindex="1002"
                    index={0}
                    isOpen={openDropdownIndex === 0}
                    toggleDropdown={toggleDropdown}
                    />
                    <Inputfield
                    fieldname="Zeitpunkt der Anmeldung"
                    placeholder="TT.MM.JJJJ"
                    styling="disabled"
                    type="date"
                    readOnly={true}
                    mandatory="none"
                    />
                    <div className="inputwrapper">
                        <div className="fieldnamewrapper">
                            <span>Niederlassungsnummer</span>
                        </div>
                        <input 
                            type="text" 
                            value={mainHeaderData.dienststellennummer} 
                            onChange={(e) => handleInputChange({ name: "dienststellennummer", value: e.target.value })} 
                        />
                    </div>
                    <TextDropDownButton
                    fieldname="Ausfuhrland"
                    zindex="1001"
                    index={1}
                    isOpen={openDropdownIndex === 1}
                    toggleDropdown={toggleDropdown}
                    />                    
                    <TextDropDownButton
                    fieldname="Bestimmungsland"
                    zindex="1000"
                    index={2}
                    isOpen={openDropdownIndex === 2}
                    toggleDropdown={toggleDropdown}
                    />         
                </div>
                <div className="form_wrapper">
                    <h2>Anmeldeart</h2>
                    <TextDropDownButton
                    fieldname="Art der Anmeldung"
                    zindex="999"
                    index={3}
                    isOpen={openDropdownIndex === 3}
                    toggleDropdown={toggleDropdown}
                    />                    
                    <DropDown
                    fieldname="Art der Ausfuhranmeldung"
                    zindex="998"
                    index={4}
                    isOpen={openDropdownIndex === 4}
                    toggleDropdown={toggleDropdown}
                    />         
                </div>

                <div className="form_wrapper">
                    <h2>Allgemeine Angaben</h2>
                    <Inputfield
                    fieldname="Datum des Augangs"
                    type="date"
                    styling="disabled"
                    readOnly={true}
                    mandatory="none"
                    />
                    <TextDropDownButton
                    fieldname="Sicherheit"
                    zindex="998"
                    index={5}
                    isOpen={openDropdownIndex === 5}
                    toggleDropdown={toggleDropdown}
                    />       
                    <Inputfield
                    fieldname="Maßgebliches Datum"
                    type="date"
                    styling="disabled"
                    readOnly={true}
                    mandatory="none"
                    />
                    <DropDown
                    fieldname="Container-Indikator"
                    zindex="997"
                    index={6}
                    isOpen={openDropdownIndex === 6}
                    toggleDropdown={toggleDropdown}
                    />
                    <div className="inputwrapper">
                        <div className="fieldnamewrapper">
                            <span>Niederlassungsnummer</span>
                        </div>
                        <input 
                            type="text" 
                            value={mainHeaderData.dienststellennummer} 
                            onChange={(e) => handleInputChange({ name: "dienststellennummer", value: e.target.value })} 
                        />
                    </div>
                    <div className="inputwrapper">
                        <div className="fieldnamewrapper">
                            <span>Bezugsnummer (LRN)</span>
                        </div>
                        <input 
                            type="text" 
                            value={mainHeaderData.lrn} 
                            onChange={(e) => handleInputChange({ name: "lrn", value: e.target.value })} 
                        />
                    </div>
                    <div className="inputwrapper">
                        <div className="fieldnamewrapper">
                            <span>Referenznummer (UCR)</span>
                        </div>
                        <input 
                            value={mainHeaderData.referenznummerUCR} 
                            onChange={(e) => handleInputChange({ name: "referenznummerUCR", value: e.target.value })} 
                        />
                    </div>
                    <TextDropDownButton
                    fieldname="Beförderungskosten (Zahlungsart)"
                    zindex="996"
                    index={7}
                    isOpen={openDropdownIndex === 7}
                    toggleDropdown={toggleDropdown}
                    />     
                    <TextDropDownButton
                    fieldname="Indikator für Besondere Umstände"
                    zindex="995"
                    index={8}
                    isOpen={openDropdownIndex === 8}
                    toggleDropdown={toggleDropdown}
                    />     
                    <div className="inputwrapper">
                        <div className="fieldnamewrapper">
                            <span>Gesamtrohmasse</span>
                        </div>
                        <input 
                            type="text" 
                            value={mainHeaderData.gesamtRohmasse} 
                            onChange={(e) => handleInputChange({ name: "gesamtRohmasse", value: e.target.value })} 
                        />
                    </div>
                    <TextDropDownButton
                    fieldname="Beteiligten-Konstellation"
                    zindex="994"
                    index={9}
                    isOpen={openDropdownIndex === 9}
                    toggleDropdown={toggleDropdown}
                    />  
                </div>

                <div className="form_wrapper">
                    <h2>Angaben zur Gestellung</h2>
                    <Inputfield
                    fieldname="Zeitpunkt der Anmeldung"
                    placeholder="TT.MM.JJJJ"
                    styling="disabled"
                    type="date"
                    readOnly={true}
                    mandatory="none"
                    />
                    <Inputfield
                    fieldname="Zeitpunkt der Anmeldung"
                    placeholder="TT.MM.JJJJ"
                    styling="disabled"
                    type="date"
                    readOnly={true}
                    mandatory="none"
                    />
                </div>

                <div className="form_wrapper">
                    <h2>Angaben zum Beförderungsmittel</h2>
                    <TextDropDownButton
                    fieldname="Inländischer Verkehrszweig"
                    zindex="993"
                    index={10}
                    isOpen={openDropdownIndex === 10}
                    toggleDropdown={toggleDropdown}
                    />
                    <TextDropDownButton
                    fieldname="Verkehrszweig an der Grenze"
                    zindex="992"
                    index={11}
                    isOpen={openDropdownIndex ===11}
                    toggleDropdown={toggleDropdown}
                    /> 
                    <TextDropDownButton
                    fieldname="Grenzüberschreitendes aktives Beförderungsmittel / Art der Identifikation"
                    zindex="991"
                    index={12}
                    isOpen={openDropdownIndex === 12}
                    toggleDropdown={toggleDropdown}
                    />
                    <DropDown
                    fieldname="Grenzüberschreitendes aktives Beförderungsmittel / Kennzeichen"
                    zindex="990"
                    index={13}
                    isOpen={openDropdownIndex === 13}
                    toggleDropdown={toggleDropdown}
                    />
                    <TextDropDownButton
                    fieldname="Grenzüberschreitendes aktives Beförderungsmittel / Staatszugehörigkeit"
                    zindex="989"
                    index={14}
                    isOpen={openDropdownIndex === 14}
                    toggleDropdown={toggleDropdown}
                    />      
                </div>

                <div className="form_wrapper">
                    <h2>Angaben zu den Dienststellen</h2>
                    <TextDropDownButton
                    fieldname="Ausfuhrzollstelle"
                    zindex="988"
                    index={15}
                    isOpen={openDropdownIndex === 15}
                    toggleDropdown={toggleDropdown}
                    />
                    <TextDropDownButton
                    fieldname="Ausfuhrzollstelle für die ergänzende/ersetzende anmeldung"
                    zindex="987"
                    index={16}
                    isOpen={openDropdownIndex ===16}
                    toggleDropdown={toggleDropdown}
                    /> 
                    <TextDropDownButton
                    fieldname="Vorgesehene Ausgangszollstelle"
                    zindex="986"
                    index={17}
                    isOpen={openDropdownIndex === 17}
                    toggleDropdown={toggleDropdown}
                    />
                    <TextDropDownButton
                    fieldname="Tatsächliche Ausgangszollstelle"
                    zindex="985"
                    index={18}
                    isOpen={openDropdownIndex === 18}
                    toggleDropdown={toggleDropdown}
                    />
                    <TextDropDownButton
                    fieldname="Gestellungszollstelle"
                    zindex="984"
                    index={19}
                    isOpen={openDropdownIndex === 19}
                    toggleDropdown={toggleDropdown}
                    />      
                </div>

                <div className="form_wrapper">
                    <h2>Angaben zu dem Geschäftsvorgang</h2>
                    <TextDropDownButton
                    fieldname="Art des Geschäfts"
                    zindex="983"
                    index={20}
                    isOpen={openDropdownIndex === 20}
                    toggleDropdown={toggleDropdown}
                    />
                    <Inputfield
                    fieldname="In Rechnung gestellter Gesamtbetrag"
                    type="text"
                    mandatory="mandatory"
                    />
                    <TextDropDownButton
                    fieldname="Rechnungswährung"
                    zindex="982"
                    index={21}
                    isOpen={openDropdownIndex ===21}
                    toggleDropdown={toggleDropdown}
                    /> 
                </div>

                <div className="form_wrapper">
                    <h2>Angaben zu den Lieferbedingungen</h2>
                    <TextDropDownButton
                    fieldname="Incoterms-Code"
                    zindex="981"
                    index={22}
                    isOpen={openDropdownIndex === 22}
                    toggleDropdown={toggleDropdown}
                    />
                    <TextDropDownButton
                    fieldname="UN/Locode"
                    zindex="980"
                    index={23}
                    isOpen={openDropdownIndex ===23}
                    toggleDropdown={toggleDropdown}
                    />
                    <DropDown
                    fieldname="Ort"
                    zindex="979"
                    index={24}
                    isOpen={openDropdownIndex === 24}
                    toggleDropdown={toggleDropdown}
                    />
                    <TextDropDownButton
                    fieldname="Land"
                    zindex="978"
                    index={25}
                    isOpen={openDropdownIndex ===25}
                    toggleDropdown={toggleDropdown}
                    />
                    <Inputfield
                    fieldname="Text"
                    type="text"
                    mandatory="none"
                    styling="disabled"
                    readOnly={true}
                    />
                </div>


            </div>
            <div className="header_right_column_wrapper">
                <h2>Beteiligtenkonstelation</h2>
                <div className="accordion">
                    <AccordionForm
                    headline="Empfänger"
                    />
                    <AccordionForm
                    headline="Anmelder"
                    />
                    <AccordionForm
                    headline="Vertreter"
                    />
                    <AccordionForm
                    headline="Subunternehmer"
                    />
                    <AccordionForm
                    headline="Ausführer"
                    />
                    <AccordionForm
                    headline="Außenwirtschaftlicher Ausführer"
                    />                    
                    <AccordionForm
                    headline="Beförderer"
                    />
                    <AccordionForm
                    headline="Versender"
                    />
                </div>

                <div className="form_wrapper">
                    <h2>Angaben zum Warenort</h2>
                    <TextDropDownButton
                    fieldname="Art des Ortes"
                    zindex="977"
                    index={26}
                    isOpen={openDropdownIndex === 26}
                    toggleDropdown={toggleDropdown}
                    />
                    <TextDropDownButton
                    fieldname="Art der Ortsbestimmung"
                    zindex="976"
                    index={27}
                    isOpen={openDropdownIndex ===27}
                    toggleDropdown={toggleDropdown}
                    />
                    <Inputfield
                    fieldname="Bewilligungsnummer"
                    type="text"
                    mandatory="none"
                    styling="disabled"
                    readOnly={true}
                    />
                    <DropDown
                    fieldname="Zusätzliche Kennung"
                    zindex="975"
                    index={28}
                    isOpen={openDropdownIndex === 28}
                    toggleDropdown={toggleDropdown}
                    />
                    <TextDropDownButton
                    fieldname="UN/Locode"
                    zindex="974"
                    index={29}
                    isOpen={openDropdownIndex === 29}
                    toggleDropdown={toggleDropdown}
                    />
                    <Inputfield
                    fieldname="GNNS Breite"
                    type="text"
                    mandatory="none"
                    styling="disabled"
                    readOnly={true}
                    />
                    <Inputfield
                    fieldname="GNNS Länge"
                    type="text"
                    mandatory="none"
                    styling="disabled"
                    readOnly={true}
                    />
                </div>

                <div className="accordionwrapper">
                    <div className="accordion">
                        <AccordionForm
                        headline="Warenort / Adresse"
                        />
                    </div>
                </div>
            </div>
        </div> 
        </>) : (<LoadingPage/>) } 
            
        </>
     );
}

export default FillHeaderInformation;

