import React, {useState} from 'react';

//importing the Compoenents
import MainHeader from '../../components/header/MainHeader';
import HeadLine from '../../components/toolbar/HeadLine';
import ToolBar from '../../components/toolbar/ToolBar';
import SectionNav from '../../components/navigation/SectionNav';
import HeaderInformation from '../../components/sections/createatlasmessage/Header/HeaderInformation';
import ApprovalSection from '../../components/sections/createatlasmessage/Header/ApprovalSection';
import SupplyChainParticipant from '../../components/sections/createatlasmessage/Header/SupplyChainParticipant';
import ReimportSection from '../../components/sections/createatlasmessage/Header/ReimportSection'; 

//Importing the css
import '../../css/pages/CreateAtlasMessage.css';

function CreateAtlasMessage() {

    const [SectionActive, setSectionActive] = useState("Kopfdaten");

    return ( 
        <>
        <div className="wrapper">
            <MainHeader/>
        </div>
        <div className="sectionwrapper">
            <div className="wrapper">
                <HeadLine headline="Anmeldung zur Ausfuhr"/>
                <ToolBar/>
            </div>

            <div className="contentwrapper">
                <SectionNav 
                currentSection={SectionActive}
                setSection={setSectionActive}
                />
                <div className="CreateAtlasMessage_Mainwrapper">
                    {SectionActive === "Kopfdaten" ? (<HeaderInformation/>) : ""}
                    {SectionActive === "Bewilligung" ? (<ApprovalSection/>) : ""}
                    {SectionActive === "Lieferketten-Beteiligter" ? (<SupplyChainParticipant/>) : ""}
                    {SectionActive === "Wiedereinfuhr" ? (<ReimportSection/>) : ""}
                </div>
            </div>
        </div>
        </>
     );
}

export default CreateAtlasMessage;


