import React from 'react';

//importing the css
import '../../css/input fields/DropDown.css';

function DropDown({index, toggleDropdown, isOpen, ...props}) {

    const dropdownHandler = () => {
        toggleDropdown(index);
    };

    return ( 
        <>
        <div className="dropdownwrapper" style={{zIndex: props.zindex}}>
                <div className="dropdownnamewrapper">
                    <span>{props.fieldname}</span>
                </div>
                <div className="dropdown_btn_wrapper">
                    <button onClick={dropdownHandler}>
                        Bitte auswählen...
                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#5f6368">
                            <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z"/>
                        </svg>
                    </button>


                        <div className={`dropdown_content_box ${isOpen ? 'dropdown_visible' : ''}`}>
                        <input type="text" placeholder='Suchen...'/>
                        <ul>
                            <li>Option 1</li>
                            <li>Option 2</li>
                            <li>Option 3</li>
                            <li>Option 3</li>
                            <li>Option 3</li>
                            <li>Option 3</li>
                            <li>Option 3</li>
                            <li>Option 3</li>
                            <li>Option 3</li>
                            <li>Option 3</li>
                            <li>Option 3</li>
                        </ul>
                    </div>
                
                </div>
        </div>
        </>
     );
}

export default DropDown;