import React from 'react';

//importing the css
import '../../css/input fields/TextDropDownButton.css';

function TextDropDownButton({index, toggleDropdown, isOpen, ...props}) {

    const dropdownHandler = () => {
        toggleDropdown(index);
    };

    return ( 
        <>
        <div className="textdropdownwrapper" style={{zIndex: props.zindex}}>
            <div className="dropdownnamewrapper">
                <span>{props.fieldname}</span>
            </div>
            <div className="textdropdown_btn_wrapper">
                <div className="textdropdownrowwrapper">
                    <button onClick={dropdownHandler}>
                        <input type="text" placeholder='Bitte auswählen...'/>
                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#5f6368">
                            <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z"/>
                        </svg>
                    </button>

                    <div className="addbutton">
                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#5f6368">
                            <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/>
                        </svg>
                    </div>
                </div>


                <div className={`textdropdown_content_box ${isOpen ? 'dropdown_visible' : ''}`}>
                    <ul>
                        <li>Option 1</li>
                        <li>Option 2</li>
                        <li>Option 3</li>
                        <li>Option 3</li>
                        <li>Option 3</li>
                        <li>Option 3</li>
                        <li>Option 3</li>
                        <li>Option 3</li>
                        <li>Option 3</li>
                        <li>Option 3</li>
                        <li>Option 3</li>
                    </ul>
                </div>
            </div>
        </div>
        </>
     );
}

export default TextDropDownButton;