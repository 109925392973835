import React from 'react';

import '../../css/toolbar/HeadLine.css';

function HeadLine(props) {
    return ( 
        <>
        <div className="headlinewrapper">
            <span>{props.headline}</span>
        </div>
        </>
     );
}

export default HeadLine;