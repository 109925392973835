import React, { useState } from 'react';

//importing the css
import '../../../../css/section/createatlasmessage/header/ApprovalSection.css';

//importing the components
import Inputfield from '../../../inputfields/InputField';
import SectionTableSupplyChain from '../../../datatables/SectionTableSupplyChain';
import TextDropDownButton from '../../../inputfields/TextDropDownButton';

function SupplyChainParticipant(props) {

    
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

    const toggleDropdown = (index) => {
        setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return ( 
        <>
        <div className="section_wrapper">
            <h2>Liferketten-Beteiligter</h2>
            <div className="section_input_container">
                <div className="inputcontainer">
                    <Inputfield
                    fieldname="Anzahl der Einträge"
                    styling="disabled"
                    readOnly={true}
                    value="0"
                    />
                    <TextDropDownButton
                    fieldname="Funktion des Beteilligten"
                    zindex="1000"
                    index={1}
                    isOpen={openDropdownIndex === 1}
                    toggleDropdown={toggleDropdown}
                    />
                    <Inputfield
                    fieldname="Identifikationsnummer"
                    readOnly={false}
                    />
                </div>
            </div>
            <div className="section_table_container">
                <SectionTableSupplyChain/>
            </div>
        </div>
        </>
     );
}

export default SupplyChainParticipant;