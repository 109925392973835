import React, { useState } from 'react';

//importing the css
import '../../css/forms/AccordionForm.css';

//importing the components
import DropDownButton from '../inputfields/DropDownButton';

function AccordionForm(props) {

    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [openAccordionIndex, setOpenAccordionIndex] = useState(false);

    const toggleAccordion = () => {
        setOpenAccordionIndex(!openAccordionIndex);
    }



    const toggleDropdown = (index) => {
        setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    };




    return ( 
        <>
        <div className="accordionwrapper">
            <div className="accordion_container">
                <div className="accordionbutton" onClick={toggleAccordion}>
                    <h2>{props.headline}</h2>
                    {openAccordionIndex ? (<>
                        <span>-</span>
                    </>) : (<>
                        <span>+</span>
                    </>)}
                </div>

                <div className={openAccordionIndex ? "accordioncontent" : "contenthidden"}>
                    <DropDownButton
                    index={1}
                    isOpen={openDropdownIndex === 1}
                    toggleDropdown={toggleDropdown}
                    />

                    <div className="content">
                        <div className="contentgrid">
                            <div className="left">
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Identifikationsnummer</span>
                                    <span>Test</span>
                                </div>
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Niederlassungsnummer</span>
                                    <span>Test</span>
                                </div>
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Name</span>
                                    <span>Test</span>
                                </div>
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Straße und Hausnummer</span>
                                    <span>Test</span>
                                </div>
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Postleitzahl</span>
                                    <span>Test</span>
                                </div>
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Ort</span>
                                    <span>Test</span>
                                </div>
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Land</span>
                                    <span>Test</span>
                                </div>
                            </div>
                            <div className="right">
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Kundennummer</span>
                                    <span>Test</span>
                                </div>
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Angezeigter Name</span>
                                    <span>Test</span>
                                </div>
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>TIN EU</span>
                                    <span>Test</span>
                                </div>
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Gültig ab: (Datum)</span>
                                    <span>Test</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
     );
}

export default AccordionForm;