import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//importing the Compoenents
import MainHeader from '../../components/header/MainHeader';
import HeadLine from '../../components/toolbar/HeadLine';
import ToolBar from '../../components/toolbar/ToolBar';

//Importing the css
import '../../css/section/general/PendingDeliveries.css';

import React from 'react';

import { reybexurl } from '../../apis/urltemplate';

function PendingDeliveries() {

    const [pendingDeliveries, setPendingDeliveries] = useState();
    const [LsNumber, SetLsNumber] = useState();

    React.useEffect(() => {

        const getPendingDeliveries = async () => {
            try {
                // Token aus dem Local Storage abrufen
                const token = localStorage.getItem("Rtoken");
    
                // Anfrage an die API senden
                const response = await fetch(`${reybexurl}domains/salesHead?docType.name=eq(Lieferschein)`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Basic ${token}`
                    }
                });
    
                // Antwort überprüfen und Daten verarbeiten
                if (response.ok) {
                    const data = await response.json();
                    setPendingDeliveries(data);
                    console.log("These are the pending deliveries:", data);
                } else {
                    throw new Error('Fehler beim Abrufen der Daten');
                }
            } catch (error) {
                console.error(error);
            }
        };
    
        // Diese Funktion wird nur einmal beim ersten Rendern der Komponente ausgeführt
        getPendingDeliveries();
    
    }, []);

    const goTo = useNavigate();
    //open an atlas message: 
    React.useEffect(() => {
        if (LsNumber) {
            goTo(`/filledatlasmessage/${LsNumber}`);
        }
    }, [LsNumber, goTo]);

    const openAtlasMessage = (lsnumber) => {
        SetLsNumber(lsnumber);
    };

    return ( 
        <>
        <div className="wrapper">
            <MainHeader/>
        </div>
        <div className="sectionwrapper">
            <div className="wrapper">
                <HeadLine headline="Arbeitsvorrat"/>
                <ToolBar/>
            </div>

            <div className="pendingdeliveries_wrapper">
                <div className="tablebox">
                    <table>
                        <thead>
                            <tr>
                                <th>Nr.</th>
                                <th>Lieferschein Nr.</th>
                                <th>Erstellt am.</th>
                                <th>Kunde</th>
                                <th>Bestimmungsland</th>
                                <th>Positionen</th>
                                <th>Rechnungsbetrag</th>
                            </tr>
                        </thead>

                        <tbody>
                            {pendingDeliveries?.map((deliverynotes, index) => (
                            <tr key={index} onDoubleClick={() => openAtlasMessage(deliverynotes.docNumber, index)}>
                                <td>{index + 1}</td>
                                <td>{deliverynotes.docNumber}</td>
                                <td>{deliverynotes.dateCreated}</td>
                                <td>{deliverynotes.customer.name}</td>
                                <td>{`${deliverynotes.customerCountry.name}`}</td>
                                <td>{deliverynotes.items.length}</td>
                                <td>{deliverynotes.grossAmount.toFixed(2) + "€"}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </>
     );
}

export default PendingDeliveries;


