import React from 'react'

import '../../../css/addons/LoadingPage.css';

function LoadingPage() {
    return ( <>
    <div className="loadingwrapper">
        <div className="wrapperbackground">

        </div>

        <div className="loadingwrapper">
            <div className="spinner_wrapper">
                <div className="Spinner1">

                </div>
                <div className="Spinner2">

                </div>
                <div className="Spinner3">
                    
                </div>
            </div>
        </div>

    </div>
    </> );
}

export default LoadingPage;