import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

//importing the css
import '../../css/pages/setup/SetUpAtlas.css';

//Improting functions
import { AtlasAuth } from '../../apis/login/AtlasAuth';

function BeoAtlasSetup() {

    const [Username, setUsername] = useState('');
    const [Password, setPassword] = useState('');
    const [token, setToken] = useState(''); // [1

    const goTo = useNavigate();

    const handleAuth = async () => {
        AtlasAuth(Username, Password, token, goTo)
    }


    return ( 
        <>
        <div className="pagewrapper">
            <div className="setupwrapper">
                <div className="container">
                    <h2>Atlas Anmeldung</h2>
                    <div className="fullsizeinputwrapper">
                        <input type="text" placeholder='Token...' onChange={(e) => setToken(e.target.value)}/>
                        <input type="text" placeholder='Benutzername...' onChange={(e) => setUsername(e.target.value)}/>
                        <input type="password" placeholder='Passwort...'  onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                    <div className="buttonwrapper">
                        <button onClick={handleAuth}>Fetigstellen</button>
                    </div>
                </div>
            </div>
        </div>
        </>
     );
}

export default BeoAtlasSetup;