import React from 'react';

//importing the css
import '../../css/datatables/NormalTable.css';

function SectionTableSupplyChain() {
    return ( 
        <>
        <div className="tablebox">
            <table>
                <thead>
                    <tr>
                        <th>Anzahl der Einträge</th>
                        <th>Funktion des Beteilligten</th>
                        <th>Identifikationsnummer</th>
                        <th>Option</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>Wert 1</td>
                        <td>Wert 2</td>
                        <td>Wert 3</td>
                        <td>Wert 4</td>
                    </tr>
                </tbody>
            </table>
        </div>
        </>
     );
}

export default SectionTableSupplyChain;