import React from 'react';

//Importing the css
import '../../css/input fields/InputField.css';

function Inputfield(props) {

    const inputRef = React.useRef(null);

    const handleClick = () => {
      inputRef.current.select();
    };

    const handleChange = (e) => {
        const { name, value } = e.target || e;
        props.onChange({ name, value }); // Übergeben Sie den Namen und Wert des Feldes an den onChange-Handler
    };


    return ( 
        <>
        <div className="inputwrapper">
            <div className="fieldnamewrapper">
                <span>{props.fieldname}</span>
            </div>
            <input 
            type={props.type} 
            placeholder={props.placeholder} 
            className={props.styling} 
            readOnly={props.readOnly} 
            value={props.value} 
            onChange={handleChange} 
            onClick={handleClick}  
            ref={inputRef}
            />
        </div>
        </>
     );
}

export default Inputfield;