import React, {useState} from 'react';

//importing the Compoenents
import MainHeader from '../../../components/header/MainHeader';
import HeadLine from '../../../components/toolbar/HeadLine';
import ToolBar from '../../../components/toolbar/ToolBar';
import SectionNav from '../../../components/navigation/SectionNav';
import FillHeaderInformation from '../../../components/OpenDataDisplay/FillAtlasMessageHeader';
import ApprovalSection from '../../../components/sections/createatlasmessage/Header/ApprovalSection';
import SupplyChainParticipant from '../../../components/sections/createatlasmessage/Header/SupplyChainParticipant';
import ReimportSection from '../../../components/sections/createatlasmessage/Header/ReimportSection'; 

//Importing the css
import '../../../css/pages/CreateAtlasMessage.css';

import { produce } from 'immer';

function FillAtlasMessage() {

    const [SectionActive, setSectionActive] = useState("Kopfdaten");
    const [formData, setFormData] = useState({
        "module": {
            "ausfuhr":{
                "data": true,
                "email": "f.burkhard@beo-software.de",
                "autosend": false
            },
            "messageData":{
                "ausfuhr": {
                    "expdat": [
                        {
                            kopf: {}
                        }
                    ]
                }
            }
        }
    });

    const handleHeaderInfoChange = (data) => {
        setFormData(produce(draft => {
            draft.module.messageData.ausfuhr.expdat[0].kopf = data;
        }));
    }

    console.log(formData);

    const handleAtlasCreation = () => {

        const Atoken = localStorage.getItem('Atoken');
        const TokenFromAtlas = localStorage.getItem('token');

        fetch('https://atlas.beo-software.de/BeoAtlasService/RestInterface/v1/PutData', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'token': TokenFromAtlas,
                'bearertoken': Atoken
            },
            body: JSON.stringify(formData)
        }).then(response => {
            if (!response.ok) { // Corrected from "Response" to "response"
                console.log(response);
                throw new Error('Network response was not ok'); // Throw an error for non-successful responses
            }
            return response.json(); // Parse JSON from successful response
        }).then(data => {
            console.log(data); // Handle successful response data
        }).catch(error => {
            console.error('Error during Atlas creation:', error); // Log any errors
            // Additional error handling or notification logic
        });
    };
    


    return ( 
        <>
        <div className="wrapper">
            <MainHeader/>
        </div>
        <div className="sectionwrapper">
            <div className="wrapper">
                <HeadLine headline="Anmeldung zur Ausfuhr"/>
                <ToolBar
                onClick={handleAtlasCreation}
                />
            </div>

            <div className="contentwrapper">
                <SectionNav 
                currentSection={SectionActive}
                setSection={setSectionActive}
                />
                <div className="CreateAtlasMessage_Mainwrapper">
                    {SectionActive === "Kopfdaten" ? (<FillHeaderInformation onChange={handleHeaderInfoChange}/>) : ""}
                    {SectionActive === "Bewilligung" ? (<ApprovalSection/>) : ""}
                    {SectionActive === "Lieferketten-Beteiligter" ? (<SupplyChainParticipant/>) : ""}
                    {SectionActive === "Wiedereinfuhr" ? (<ReimportSection/>) : ""}
                </div>
            </div>
        </div>
        </>
     );
}

export default FillAtlasMessage;


