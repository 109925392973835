import React, {useState} from 'react';

//importing the css
import '../../css/pages/setup/SetUpReybex.css';

//Improting functions
import { ReyBexAuth } from '../../apis/login/ReyBexAuth';
import { useNavigate } from 'react-router-dom';

function ReyBexSetup() {

    const goTo = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleAuth = async () => {
        await ReyBexAuth(username, password, goTo);
    }


    return ( 
        <>
        <div className="pagewrapper">
            <div className="setupwrapper">
                <div className="container">
                    <h2>RexBex Anmeldung</h2>
                    <div className="fullsizeinputwrapper">
                        <input type="text" placeholder='Benutzername...' onChange={(e) => setUsername(e.target.value)}/>
                        <input type="password" placeholder='Passwort...'  onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                    <div className="buttonwrapper">
                        <button onClick={handleAuth}>Weiter</button>
                    </div>
                </div>
            </div>
        </div>
        </>
     );
}

export default ReyBexSetup;