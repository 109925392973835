export const AtlasAuth = async (Username, Password, token, goTo) => {

    localStorage.setItem('token', token)

    const authUser = btoa(`${Username}:${Password}`);


    try {
        const data = await fetch("https://atlas.beo-software.de/BeoAtlasService/RestInterface/v1/authenticate", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${authUser}`
            }
        })

        console.log(authUser)

        const response = await data.json()
        console.log(response)

        localStorage.setItem('Atoken', response.accessToken)
        goTo("/kopfdatenatlasausfuhr")

    } catch (error) {
        console.error(error)
    }
}