import React from 'react';

//importing the CSS
import '../../css/toolbar/ToolBar.css';

function ToolBar(props) {
    return ( 
        <>
        <div className="toolbarwrapper">
            <button className='beo_btn_organge'>
                <span>Positionsdaten</span>
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#5f6368">
                    <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z"/>
                </svg>
            </button>
            <button className='beo_btn_transparent' onClick={props.onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#5f6368">
                    <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
                </svg>
                <span>Abbrechen</span>
            </button>
        </div>
        </>
     );
}

export default ToolBar;