import React from 'react';
import ReactDOM from 'react-dom/client';

//imorting Provider
import { ToastContainer } from 'react-toastify';

//Importing the CSS
import './css/global.css';
import 'react-toastify/dist/ReactToastify.css';

//Importing the Router moduls
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//Setup pages
import ReyBexSetup from './pages/Setup/SetUpReybex';
import BeoAtlasSetup from './pages/Setup/SetUpAtlas';

//Importing the pages
import CreateAtlasMessage from './pages/Userpages/CreateAtlasMessage';
import FillAtlasMessage from './pages/Userpages/OpenData.js/FillAtlasMessage';

import PendingDeliveries from './pages/Userpages/PendingDeliveries';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<ReyBexSetup/>} />
        <Route path="/beoatlassetup" element={<BeoAtlasSetup/>} />
        <Route path="/kopfdatenatlasausfuhr" element={<CreateAtlasMessage/>} />
        <Route path="/pendingdeliverynotes" element={<PendingDeliveries/>} />


        <Route path="/filledatlasmessage/:LsNumber" element={<FillAtlasMessage/>} />
      </Routes>
    </Router>
    <ToastContainer />
  </React.StrictMode>
);
